<template>
  <div>
    <b-modal
      id="modal-change-material"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeStatus')"
      hide-footer
      @close="onCancel"
    >
      <b-form style="display: flex; flex-direction:column">
        <p>
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(comingStatus.newState) }}</span> <br> {{ $t('ChangeStatusOfItem') }}
        </p>
        <b-form-group
          v-if="comingStatus.newState == 'ReadyToDeliver'"
          id="input-group-1"
          :label="`${$t('Location')}:`"
          label-for="input-1"
        >
          <vue-select
            v-model="form.locationWarehouse"
            :options="getLocationsByType"
            :placeholder="$t('SelectLocation')"
            label="locationName"
            aria-describedby="input-1-live-feedback"
            :reduce="(e) => e.locationId"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Notes')}:`"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-1"
            v-model="form.notes"
            aria-describedby="input-1-live-feedback"
          />
          <p v-if="isNotes">
            <span style="color:red;">Please show the reason why you canceling</span>
          </p>
        </b-form-group>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Yes') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
// import {
//   required,
// } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'
// import { client } from '../../../domainConfig'


export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['comingStatus'],
  data() {
    return {
      form: {
        locationWarehouse: null,
        notes: null,
      },
      isNotes: false,
    }
  },
  validations: {

  },
  computed: {
    ...mapGetters(['getOrderItemStates', 'getTeamsNames', 'getDropdownTeamName', 'getLocationsByType', 'getTeamOverview']),

  },
  watch: {

  },
  methods: {
    ...mapActions([

    ]),

    async onSubmit() {
      if ((this.form.notes == null || this.form.notes == '') && this.comingStatus.newState == 'Cancelled') {
        this.isNotes = true
        return;
      }
      try {
        if (this.form.locationWarehouse != null) {
          this.comingStatus.locationId = this.form.locationWarehouse
        }
        if (this.form.notes != null) {
          this.comingStatus.notes = this.form.notes
        }
        this.$emit('changeMaterialStatus', this.comingStatus)
        this.$refs.modal.hide()
        this.onReset()
      } catch (error) {
        console.log(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      this.onReset()
    },
    onReset() {
      this.form.locationWarehouse = null
      this.form.notes = null
      this.isNotes = false
    },
  },
}
</script>

  <style lang="scss" scoped>
    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .flex-form {
      display: flex;
      flex-direction: column;
    }
    .buttonat{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
    }
  </style>
