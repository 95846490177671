<template>
  <div>
    <MaterialsManagement />
  </div>
</template>

<script>
import MaterialsManagement from '../components/materialsNominvative/MaterialsManagement.vue'

export default {
  name: 'Materialsmanagement',
  components: {
    MaterialsManagement,
  },
}

</script>
